import UrlHelper from '@eig-builder/core-utils/helpers/url-helper'
import RuntimeConfigHelper from '@eig-builder/core-utils/helpers/runtime-config-helper'

import { isHostedBrand } from 'shared/account-type-helper'

import Communication from './communication'
import * as events from 'shared/events'

let configuration = {}

class ConfigurationManager {
  static setConfiguration (newConfiguration) {
    configuration = newConfiguration

    if (configuration.displayName) {
      document.title = configuration.displayName

      // TODO remove this
      // Hack for favicon because the backend fix is going live later
      // if (configuration.displayName.toLowerCase().includes('hostgator')) {
      //   ConfigurationManager.changeFavicon('https://components.mywebsitebuilder.com/hostgator/favicon.png')
      // }
    }

    Communication.send(events.CONFIG_CHANGED, true)
  }

  static changeFavicon (src) {
    const head = document.head || document.getElementsByTagName('head')[0]

    const link = document.createElement('link')
    const oldLink = document.getElementById('dynamic-favicon')
    link.id = 'dynamic-favicon'
    link.rel = 'shortcut icon'
    link.href = src
    if (oldLink) {
      head.removeChild(oldLink)
    }
    head.appendChild(link)
  }

  static getLogoutUrl () {
    return configuration.logout || UrlHelper.getUrl(UrlHelper.siteNames.LOGIN, '/logout')
  }

  static getLoginUrl () {
    return configuration.login || UrlHelper.getUrl(UrlHelper.siteNames.LOGIN, '')
  }

  static setApiKeysFromConfig (apiKeys) {
    const mapApiKey = configuration['googleApiKey']
    if (mapApiKey) {
      apiKeys['googleMapsApiKey'] = mapApiKey
    }
  }

  static setUrlsForPublish (urls) {
    urls[UrlHelper.siteNames.RE_API] = UrlHelper.getUrl(UrlHelper.siteNames.RE_API)
    urls[UrlHelper.siteNames.HOSTING_API] = UrlHelper.getUrl(UrlHelper.siteNames.HOSTING_API)
    urls['dataproxy'] = UrlHelper.getDataProxyUrl()
  }

  static getHelpUrl () {
    return configuration.helpCenter || UrlHelper.getHelpUrl()
  }

  static getManageSitesUrl () {
    return configuration.createNewSite || configuration.cp || UrlHelper.getUrl(UrlHelper.siteNames.MY, '/sites')
  }

  static getUpgradeUrl (siteId) {
    if (configuration.upgradeUrl) {
      if (configuration.upgradeUrl.endsWith('=')) {
        return configuration.upgradeUrl + siteId
      }
      return configuration.upgradeUrl
    }

    return configuration.cp
  }

  static isUpgradeButtonHidden () {
    if (configuration.upgradeUrl) {
      if (configuration.upgradeUrl.toLowerCase().endsWith('hidden')) {
        return true
      }
    }
    return false
  }

  static getHeaderLogo () {
    if (isHostedBrand()) {
      return configuration.logo || configuration.headerLogo || ''
    }
    return RuntimeConfigHelper.getRuntimeConfig()._brandImages.BrandLogoSVG || ''
  }

  static getWhiteLogo () {
    return RuntimeConfigHelper.getRuntimeConfig()._brandImages.BrandLogoWhite || ''
  }

  // static getWhiteLogo () {
  //   return RuntimeConfigHelper.getRuntimeConfig()._brandImages.BrandLogoWhite || ''
  // }

  static getPublicUrl () {
    return configuration.cp || UrlHelper.getUrl(UrlHelper.siteNames.CP_MY)
  }
}

export default ConfigurationManager

import * as constants from './constants'

import FetchHelper from '@eig-builder/core-utils/helpers/fetch-helper'
import Fetch from 'shared/fetch-wrapper'
import UrlHelper from '@eig-builder/core-utils/helpers/url-helper'

import { isHostedBrand } from 'shared/account-type-helper'

const billingApiUrl = (path, param) => UrlHelper.getUrl(UrlHelper.siteNames.BILLING_API, `/v1.0/${path}`, null, param)
const limitationUrl = param => billingApiUrl(`limitation`, param)

const reApiUrl = (path, param) => UrlHelper.getUrl(UrlHelper.siteNames.RE_API, `/v1.0/${path}`, null, param)
const configurationUrl = (path, param) => reApiUrl(`token/config`)

export const getBrandConfiguration = () => {
  return FetchHelper.fetchAsJson(configurationUrl(), {
    key: constants.GET_BRAND_CONFIGURATION,
    convertToCamelCase: true
  })
}

export const getLimitations = (siteId = 0, callback) => {
  return (dispatch, getState) => {
    const dispatchAction = jwt => {
      const partnerId = getState().auth.partnerId || getState().configuration.partnerId

      if (!partnerId) {
        return
      }

      // TODO fix this better
      const options = {
        key: constants.GET_BRAND_LIMITATIONS,
        convertToCamelCase: isHostedBrand()
      }

      if (jwt) {
        options.fetchOptions = {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        }
      }
      const limitationOptions = {
        product: 'expresseditor',
        partnerId: partnerId
      }
      if (siteId) {
        limitationOptions.siteId = siteId
      }
      dispatch(
        FetchHelper.fetchAsJson(
          limitationUrl(limitationOptions),
          options
        )
      ).then(() => {
        if (callback) {
          callback()
        }
      })
    }
    if (isHostedBrand()) {
      Fetch.postAsJson(reApiUrl('token/billing'), {}).then(response => {
        if (response.jwt) {
          dispatchAction(response.jwt)
        }
      })
    } else {
      dispatchAction()
    }
  }
}
